@use "functions";

@mixin media-breakpoint-xs {
  @media (max-width: 375px) {
    @content;
  }
}

@mixin media-breakpoint-sm {
  @media (max-width: 575.98px) {
    @content;
  }
}

@mixin media-breakpoint-md {
  @media (max-width: 767.98px) {
    @content;
  }
}

@mixin media-breakpoint-lg {
  @media (max-width: 991.98px) {
    @content;
  }
}

@mixin media-breakpoint-xl {
  @media (max-width: 1199.98px) {
    @content;
  }
}

@mixin media-breakpoint-1280 {
  @media (max-width: 1280px) {
    @content;
  }
}

@mixin media-breakpoint-1440 {
  @media (max-width: 1440px) {
    @content;
  }
}

@mixin media-breakpoint-1600 {
  @media (max-width: 1600px) {
    @content;
  }
}

@mixin media-breakpoint-1920 {
  @media (max-width: 1920px) {
    @content;
  }
}

@mixin mobile-landscape {
  @media (max-width: 1023px) and (orientation: landscape) {
    @content;
  }
}

// Heading breakpoints //
// If largest heading breakpoint is changed, sidenav-breakpoint in header's data attribute must be change to the same value. //
@mixin heading-breakpoint-1200 {
  @media (max-width: functions.rem(1200px)) {
    @content;
  }
}

@mixin heading-breakpoint-475 {
  @media (max-width: functions.rem(475px)) {
    @content;
  }
}

// Text overflow //
@mixin text-overflow($line) {
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Underline transition //
@mixin underline-transition($underline-width, $line-color, $line-height, $bottom-position) {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    background-color: $line-color;
    width: 0;
    height: $line-height;
    bottom: $bottom-position;
    left: (100% - $underline-width) / 2;
    transition: width .2s;
    transition-timing-function: ease-in;
  }

  &.active {
    &:after {
      width: $underline-width;
    }
  }

  &:hover:after {
    width: $underline-width;
    transition-timing-function: ease-out;
  }
}

