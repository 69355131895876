@use '../mixins';

#Error404 {
  font-size: 1.5rem;
  section {
    @include mixins.media-breakpoint-md {
      padding: 5rem 0 10rem 0;
    }
  }
  .error-wrapper {
    position: relative;
    img {
      margin-top: -9.375rem;
      @include mixins.media-breakpoint-md {
        margin-top: 0;
        width: 100%;
      }
    }
    .error-text {
      position: absolute;
      top: 21rem;
      text-align: center;
      @include mixins.media-breakpoint-md {
        top: 13.5rem;
      }
      h1 {
        font-weight: 300;
        @include mixins.media-breakpoint-md {
          font-size: 1.5rem;
        }
      }
      span {
        @include mixins.media-breakpoint-md {
          font-size: 1.125rem;
        }
      }
      .header-box {
        width: 14.75rem;
        height: 2.875rem;
        font-weight: 300;
        i {
          font-size: 2.1875rem;
          left: 0.3rem;
          bottom: 0.3rem;
        }
      }
    }
  }
}